.app {
	overflow: hidden;
}

.Loading {
	//background: #282c34;
	opacity: 0.85;
}

.fa {
	width: 55px;
	padding: 5px 15px 5px 15px;
	font-size: 30px;
	text-align: center;
	text-decoration: none;
	margin: 2px 2px;
}

.fa-facebook {
	background: #3b5998;
	color: white !important;
}

.fa-google {
	background: #dd4b39;
	color: white !important;
}

.menu-scrollable {
	overflow-y: auto;
	max-height: 500px;
}

.BorderRed {
	border-color: red;
}

.img-avatar, .img-avatar svg {
	border-radius: 50%;
	width: 36px;
	height: auto;
}

td {
	border: 1px solid #c8ced3;
}

th {
	border: 1px solid #c8ced3;
}

.sortable {
	display: flex;
	flex-direction: row;
}

.paddedHeader {
	margin: auto;
	margin-bottom: 6px;
}

.notPaddedHeader {
	margin: auto;
}

.twoThirdsWidth {
	width: 75%;
	margin: auto;
}

.middle {
	vertical-align: middle !important;
}

.backButton {
	width: 50px;
	float: "right";
}

.filterButton {
	margin-top: 15px;
	height: 32px;
}

.filter {
	margin-bottom: 10px;
}

input.filterInput {
	width: 100%;
}

.react-datepicker-wrapper {
	width: 100%;
}

.editingField {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.editableFieldIcon :first-child {
	width: 20px;
	height: 20px;
}

.editingFieldInput,
.AddItemLabel {
	margin: auto;
	text-align: center;
}

.editableField,
.AddItem {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.editableFieldLabel,
.editableFieldImage,
.AddItemLabel {
	margin-top: auto;
	margin-bottom: auto;
}

.full-width {
	width: 100%;
}

.bg-green {
	background-color: green;
}
.bg-red {
	background-color: red;
}

.search-wrapper {
	max-height: 40px;
}

/* unvisited link */
.link:link {
	color: red;
}

/* visited link */
.link:visited {
	color: green;
}

/* mouse over link */
.link:hover {
	cursor: pointer;
	color: hotpink;
}

/* selected link */
.link:active {
	color: blue;
}

.link:link {
	text-decoration: none;
}

.link:visited {
	text-decoration: none;
}

.link:hover {
	text-decoration: underline;
}

.link:active {
	text-decoration: underline;
}

svg {
	overflow: visible;
}
