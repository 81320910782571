.forgotCard {
	width: 100%;
	height: 100%;
}

.forgotCardGroup {
	width: 100%;
	height: 100%;
}

.forgotCardContainer {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}

.forgotImageContainer {
	margin: 0;
	padding: 0;
	height: 100%;
}

.forgotImage {
	max-width: 100%;
	height: 100%;
}

.logoSmall {
	max-width: 100px;
	max-height: 100px;
	margin: auto;
}

.logo {
	max-width: 200px;
	max-height: 200px;
	margin: auto;
}

@media only screen and (max-width: 576px) {
	.forgotImageContainer {
		display: none;
	}
}
